
import {
  computed,
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  toRaw,
  toRefs,
} from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import {
  erpConfirm,
  errorMessage,
  errorTip,
  exportFile,
  successTip,
} from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import { httpPost } from '@/api';
import AdvanceDetail from './components/advanceDetail.vue';
export default defineComponent({
  name: 'advancepayList',
  components: { ErpList, AdvanceDetail },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { startTimer, timeStatus } = useTimer();
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const elDialog = reactive({
      isShow: false,
      title: '',
    });
    const data = reactive({
      currentRow: null,
    });
    const customParams: any = ref();
    const formType = ref(1);

    const countryAndPlantNo = computed(() => {
      const countryDesc = (data.currentRow as any).countryDesc;
      const plantNo = (data.currentRow as any).plantNo;
      return countryDesc + '/' + plantNo;
    });
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };

    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };

    const operateFn = (status, row) => {
      if (status === '取消') {
        data.currentRow = row;
        elDialog.isShow = true;
      }
      if (status === '重新提交') {
        data.currentRow = toRaw(row);
        formType.value = 5;
        elDialog.title = '重新提交付款申请';
        showVisible();
      }
      if (status === '提交申请') {
        data.currentRow = toRaw(row);
        formType.value = 4;
        elDialog.title = '提交付款申请';
        showVisible();
      }
    };

    // 取消
    const cancelAdvancepay = async () => {
      const dataRow = data.currentRow as any;
      const { version, id, applyType } = dataRow;
      // applyType申请付款类型 1.预付款 2.尾款
      const url = '/malicrm/apply/pay/cancelApply';
      const params: any = { version, id, applyType };
      params.applyPayAmount = dataRow.expectPayAmount;
      const res = await httpPost(url, params);
      if (res.code === 200) {
        successTip('该付款申请已取消');
        refreshTable();
        // 关闭弹窗
        elDialog.isShow = false;
      } else {
        errorTip(res.message);
      }
    };

    // 预付导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/apply/pay/exportApplyPaymentNotice',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    const editAutoCert = () => {
      formType.value = 2;
      showVisible();
    };
    // 查看详情
    const rowClick = (row, column) => {
      const { label } = column;
      if (label !== '销售合同号' && label !== '操作') {
        data.currentRow = toRaw(row);
        formType.value = 3;
        elDialog.title = '查看付款申请';
        showVisible();
      }
    };

    return {
      cancelAdvancepay,
      countryAndPlantNo,
      ...toRefs(data),
      elDialog,
      exportFileFn,
      operateFn,
      formType,
      editAutoCert,
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      data,
    };
  },
});
